@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Flex:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;500;600;700;800&display=swap");
* {
  font-family: "Inter", sans-serif;
}
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
.inter {
  font-family: "Inter", sans-serif;
}
.roboto {
  font-family: "Roboto Flex", sans-serif;
}
.test {
  border: 1px solid red;
}
.navShadow {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.active {
  border-bottom: 4px solid #0226be;
  color: #0226be;
  padding-bottom: 22px;
  text-decoration: none;
  font-weight: 700 !important;
  text-underline-offset: 7px !important;
}
.nav_name {
  font-size: 18px;
  color: #4c4c4c;
  /* background-color: #4285f4; */
}
.tableBorder {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
}
.tableBorde2 {
  border-radius: 4px;
  background: #fff;
  box-shadow: 1px 0px 2px 0px rgba(0, 0, 0, 0.25);
}
.surveyCardShadow {
  filter: drop-shadow(0px 0px 5px rgba(2, 38, 190, 0.2));
}
.containerScroll::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
.hideScroll::-webkit-scrollbar {
  width: 0px;
}
/* Track */
.containerScroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; */
  width: 1px;
  display: none;
}
/* Handle */
.containerScroll::-webkit-scrollbar-thumb {
  background: #0226be;
  border-radius: 10px;
}
.css-j204z7-MuiFormControlLabel-root {
  margin-right: 0 !important;
}
.signupBg {
  border-radius: 24px 0px 0px 24px;
  background: linear-gradient(131deg, #325fff 0%, #5c8aff 100%);
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.12);
}
.sidebarShadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.trans {
  transform: rotate3d(0, 1, 0, 180deg);
}
.sideActive {
  /* border-radius: 0% 100% 100% 0% / 49% 48% 52% 51%; */
  border-top-right-radius: 500px 500px;
  border-bottom-right-radius: 500px 500px;
}
.nuni {
  font-family: "Nunito", sans-serif;
}
.radioChange {
  accent-color: #232323;
}
input:focus,
select:focus {
  outline: none;
}
input[type="checkbox"] {
  accent-color: #0226be;
}

.qualifi input[type="checkbox"] {
  width: 20px;
  height: 20px;
}
.dashboardShadow {
  border-radius: 11.122px;
  border: 1.608px solid var(--gray-100, #f9f9f9);
  background: var(--base-white, #fff);
  box-shadow: 0px 3.21537px 6.43075px 0px rgba(0, 0, 0, 0.04);
}
.reportsShadow {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7.5px);
}
.flashAnimation:nth-child(1) {
  animation: 0.5s ease-in 0s 3 alternate flash;
  /* animation-iteration-count: 2; */
}
@keyframes flash {
  25% {
    background: white;
  }
  50% {
    background: red;
  }
  75% {
    background: white;
  }
  100% {
    background: red;
  }
}
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #0226be;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #0226be;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
.progress {
  background-color: #eaecf2;
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25) inset;
  border-radius: 12px;
  position: relative;
  height: 10px;
}

.progress_done {
  /* background: linear-gradient(to left, #7B61FF, #9457EB); */
  background: #0226be !important;
  box-shadow: 0 3px 3px -5px #beb5d9, 0 2px 5px #66b681;
  border-radius: 24px;
  color: #fff;
  height: 100%;
  width: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 1s ease 0.3s;
}

/* .progress_done span {
  color: white;
  font-size: 12px;
  font-weight: 700;
} */
.myTransition {
  transition: all 0.4s ease;
}
.container_img {
  animation: slideIn 4s linear forwards;
  will-change: transform;
  transition: height 1s ease;
}

.textContainer {
  animation: slideBottom 0.5s linear forwards;
  will-change: transform;
  transition: bottom 0.5s ease;
}

@keyframes slideBottom {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translate(10px);
  }
  100% {
    transform: translate(550px);
  }
}

.overlay_white {
  animation: slideLeft 1s ease-in-out forwards;
  will-change: transform;
}

@keyframes slideLeft {
  0% {
    transform: translate(-90%);
  }
  100% {
    transform: translateX(0px);
  }
}

/* Add the initial position for Insights logo */
.insights-animation {
  transform: translateX(70%);
  transition: transform 1s ease;
}

/* Animate the Insights logo when showLogo is true */
.container_img.show-insights .insights-animation {
  /* transform: translateX(0); */
  background-color: #0226be;
}

/* Animate the logo section to the top */
.animate-logo-section {
  animation: moveLogoSection 1s ease-in-out forwards;
}

@keyframes moveLogoSection {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.singlePunchContainer {
}

/* media queries */
@media only screen and (max-width: 800px) {
  .mainContainer {
    /* background-color: thistle; */
    height: fit-content;
    display: block;
  }

  .singlePunchContainer,
  .multiPunchContainer {
    margin-left: 0;
    margin-right: 0;
  }
  .singlePunchContainer h1,
  .multiPunchContainer h1 {
    font-size: 28px;
  }
  .numericContainer,
  .textContainer {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 72vh;
  }
  .submitQualificationContainer {
    margin-left: 0;
    margin-right: 0;
  }
  .footerContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 15px;
    width: 100%;
    padding-left: 1.5em;
    margin-top: 2em;
  }
}

@media only screen and (max-width: 600px) {
  .mainContainer {
    /* background-color: aqua; */
  }

  .mainContainer h1 {
    font-size: 28px;
  }
}
/* custom scrollbar */
/* width */
.singlePunchContainer::-webkit-scrollbar,
.multiPunchContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.singlePunchContainer::-webkit-scrollbar-track,
.multiPunchContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(198, 198, 198);
  border-radius: 10px;
}
/* Handle */
.singlePunchContainer::-webkit-scrollbar-thumb,
.multiPunchContainer::-webkit-scrollbar-thumb {
  background: #0226be;
  border-radius: 10px;
}
/* checkbox */
.containerCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: flex-start;
  color: #333;
  font-weight: 600;
  width: 100%;
}
.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.containerCheck input:checked ~ .check {
  background-color: #0226be;
  border: 1px solid transparent;
}
.containerCheck input:checked ~ .check:after {
  display: block;
}
.containerCheck .check:after {
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 2px solid #333;
  border-radius: 4px;
}
.check:after {
  content: "";
  position: absolute;
  display: none;
}
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: flex-start;
  color: #333;
  font-weight: 600;
}

/* Hide the browser's default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 19px;
  width: 19px;
  border: 2px solid #333;
  border-radius: 50%;
}

.container input:checked ~ .radio {
  background-color: #fff;
  border: 1px solid #0226be;
}

.radio:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .radio:after {
  display: block;
}

.container .radio:after {
  top: 4px;
  left: 3.5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0226be;
}
.linearGraphColor {
  background: linear-gradient(to left, #0226be 0%, #28b5e0 100%);
}
.reconciliationShadow {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
}
.btnShadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}
@media print {
  .printInvoice {
    padding: 10px 30px;
  }
}

.questionAutoComplete .MuiInputBase-root {
  height: 40px;
  display: flex;
}

.questionAutoComplete .MuiOutlinedInput-input {
  height: 5px;
}
/* invoice print css */
@media print {
  @page {
    size: A4;
    margin: 0;
  }

  body {
    font-size: 12pt; /* Ensure readable font size */
  }

  .page-break {
    page-break-before: always;
  }

  .avoid-page-break {
    page-break-inside: avoid;
  }

  .print\:hidden {
    display: none !important;
  }

  .print\:block {
    display: block !important;
  }

  /* Adjust layout for A4 width */
  .content {
    max-width: 100%;
    width: 100%;
  }
}
